<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="700px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="force-100-height"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        dark
        class="justify-start text-none no-bg-v-btn drawer-v-btn"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('donner-avis') }}
      </v-btn>
    </template>
    <v-card class="contenu-piscinelle">
      <v-card-title
        class="dialog-card-color pa-3 white--text flex-nowrap align-start"
      >
        {{ $t('donner-avis') }}
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        style="height: 100%;"
        class="pa-4"
      >
        <p>{{ $t('texte-avis') }}</p>
        <v-form ref="form">
          <div>
            <h2 class="mx-0">
              {{ $t('vos-coordonnees') }}
            </h2>
            <p>{{ $t('nom-complet') + " : " + user.prenom + " " + user.nom }}</p>
            <p>{{ $t('mail') + " : " + user.email }}</p>
            <p>{{ $t('telephone') + " : " + user.tel1 }}</p>
            <h2 class="mx-0">
              {{ $t('laisser-message') }}
            </h2>
            <v-textarea
              v-model="message"
              name="message"
              :rules="[rules.required]"
              :label="$t('laisser-message')"
              outlined
            />
          </div>
          <v-card-actions class="my-2">
            <v-btn
              dark
              :loading="loading"
              x-large
              min-width="300px"
              class="mx-auto"
              :class="user.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover'"
              @click="envoyerAvis(message)"
            >
              {{ $t('envoyer') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: 'Avis',
  data () {
    return {
      dialog: false,
      loading: false,
      message: '',
      rules: {
        required: value => !!value || this.$t('champ-requis')
      }
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    envoyerAvis (message) {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$store.dispatch('avis', message).then(() => {
          this.dialog = false
          this.loading = false
          window.scrollTo(0, 0)
        })
      }
    }
  }
}
</script>
