<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="700px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ attrs }">
      <v-btn
        v-ripple="false"
        text
        dark
        class="justify-start text-none no-bg-v-btn drawer-v-btn"
        v-bind="attrs"
        @click="openDialog"
      >
        {{ $t('a-propos') }}
      </v-btn>
    </template>

    <v-card class="contenu-piscinelle">
      <v-card-title
        class="dialog-card-color pa-3 white--text flex-nowrap align-start"
      >
        {{ $t('a-propos') }}
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pt-5"
      >
        <v-carousel
          light
          height="auto"
          show-arrows-on-hover
          hide-delimiter-background
        >
          <v-carousel-item
            v-for="(item, index) in items"
            :key="index"
          >
            <div>
              <!-- eslint-disable vue/no-v-html -->
              <p v-html="item.contenu" />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {

  name: 'Bienvenue',
  data () {
    return {
      dialog: false,
      items: [
        { title: 'Bienvenue', id: 2286 },
        { title: 'Créer des configurations', id: 2372 },
        { title: 'Epingler vos photos', id: 2373 },
        { title: 'Votre Conseiller Piscinelle', id: 2374 },
        { title: 'Propositions commerciales', id: 2375 },
        { title: 'Suivi de votre commande', id: 2376 },
        { title: 'Votre avis', id: 2377 }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    if (this.user.show_popup) {
      this.$store.dispatch('loadCurrentUser', localStorage.getItem('token')) // Refresh user pour tester show popup
      this.openDialog()
    }
  },
  methods: {
    ...mapActions(['getContenu']),
    openDialog () {
      Promise
        .all(this.items.map(item => this.getContenu(item.id)))
        .then((contenus) => {
          contenus.forEach((contenu, index) => {
            this.$set(this.items[index], 'contenu', contenu.params.lang[this.$i18n.locale].contenu)
          })
          this.dialog = true
        })
    }
  }
}
</script>
