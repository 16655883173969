<template>
  <v-dialog
    v-model="dialogEnvoiPartageEc"
    max-width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="justify-start text-none no-bg-v-btn drawer-v-btn"
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('partager_ec') }}
      </v-btn>
    </template>
    <v-card class="py-4">
      <v-card-text>
        <p class="mb-0">
          {{ $t('adresse_proche') }}
        </p>
        <p>{{ $t('partage-personne-confiance') }}</p>
      </v-card-text>
      <v-card-actions class="flex-wrap justify-center">
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-text-field
            v-model="email"
            outlined
            :rules="emailRules"
            :label="$t('mail')"
            required
          />
          <v-btn
            :disabled="!valid"
            :loading="loading"
            class="white--text mt-2 mr-2"
            :class="user.is_petite_source ? 'background-ps-primary primary-ps-hover ps-disabled-color' : 'background-primary primary-hover'"
            @click="envoiLienEc(email)"
          >
            {{ $t('partager') }}
          </v-btn>
          <v-btn
            class="mt-2"
            @click="dialogEnvoiPartageEc = false"
          >
            {{ $t('annuler') }}
          </v-btn>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: 'PartageEC',
  data () {
    return {
      dialogEnvoiPartageEc: false,
      loading: false,
      valid: true,
      email: '',
      emailRules: [
        v => !!v || this.$t('champ-requis'),
        v => /.+@.+\..+/.test(v) || this.$t('mail-valide')
      ]
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    envoiLienEc (mail) {
      this.loading = true
      this.$store.dispatch('envoiMailProche', mail)
        .then(() => {
          this.dialogEnvoiPartageEc = false
          this.loading = false
        })
    }
  }
}
</script>
