<template>
  <div
    :class="$vuetify.breakpoint.mdAndUp ? 'col-md-3 col position-fixed' : 'burger-menu-drawer'"
    class="drawer"
    :width="$vuetify.breakpoint.mdAndDown && drawer ? '320px' : '' "
  >
    <v-app-bar
      color="transparent"
      flat
    >
      <v-app-bar-nav-icon
        color="white"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      dark
      :width="$vuetify.breakpoint.mdAndUp ? '100%' : '320px' "
      absolute
      :height="$vuetify.breakpoint.mdAndUp ? '100%' : '100vh'"
      :permanent="!$vuetify.breakpoint.smAndDown"
    >
      <div class="d-flex align-end mb-6 pt-3 px-2">
        <v-img
          :src="user.is_petite_source ? piscinelleUrl+'img/PS/ps_logo_blanc.svg' : 'https://www.piscinelle.com/img/logo.svg'"
          max-width="50"
          class="mr-2"
        />
        <div
          v-if="user.is_petite_source"
          class="white--text"
        >
          {{ $t('ec-petite-source') }}
        </div>
        <div
          v-else
          class="white--text"
        >
          {{ $t('espace-client') }}
        </div>
      </div>
      <v-list
        dense
        nav
        class="pa-0"
      >
        <v-list-item class="mb-0">
          <v-list-item-content class="pa-0">
            <v-btn
              class="justify-start text-none drawer-v-btn"
              text
              :to="{name: 'Commandes'}"
            >
              {{ $t('config-devis-commande') }}
              <span v-if="commandes"> ({{ Object.keys(commandes).length }})</span>
              <span v-else> (0)</span>
            </v-btn>
            <v-list-item
              v-for="offre in commandes"
              :key="offre.id"
              class="mb-0"
            >
              <v-list-item-content class="pa-0">
                <v-btn
                  class="justify-start text-none text-body-2"
                  text
                  :to="{name: 'Commande', params: {id: offre.id}}"
                >
                  {{ $t('publication', {type: offre.statut === 'facture' || offre.statut === 'commande' ? $t('commande.statut.commande') : (offre.internaute ? $t('commande.statut.config') : $t('commande.statut.devis')), date: $d(new Date(offre.date.replace(/-/g, "/")), 'short')}) }}
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item
          v-if="!user.is_petite_source"
          class="mb-0"
        >
          <v-list-item-content class="pa-0">
            <v-btn
              class="justify-start text-none drawer-v-btn"
              text
              :to="{name: 'Photos'}"
            >
              {{ $t('mes-photos') }}
              <template v-if="user.custom_field_array">
                <span v-if="user.custom_field_array.ec_photos"> ({{ Object.keys(user.custom_field_array.ec_photos).length }})</span>
                <span v-else> (0)</span>
              </template>
              <template v-else>
                <span> (0)</span>
              </template>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-list-item-content class="pa-0">
            <PartageEC v-if="$store.getters.isLoggedIn" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-list-item-content class="pa-0">
            <Avis v-if="$store.getters.isLoggedIn" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-list-item-content class="pa-0">
            <Welcome
              v-if="$store.getters.isLoggedIn"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!user.is_petite_source"
          class="mb-0"
        >
          <v-list-item-content class="pa-0">
            <v-btn
              class="justify-start text-none drawer-v-btn"
              text
              :href="piscinelleUrl + ($i18n.locale == 'fr' ? '' : $i18n.locale+'/') + '?email=' + user.email"
              :target="'_blank'"
            >
              Piscinelle.com<v-icon
                color="white"
                class="ml-1"
                x-small
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.client === '1' && !user.is_petite_source"
          class="mb-0"
        >
          <v-list-item-content class="pa-0">
            <v-btn
              class="justify-start text-none drawer-v-btn"
              text
              :href="piscinelleAccessoiresUrl"
              :target="'_blank'"
            >
              Piscinelle accessoires<v-icon
                color="white"
                class="ml-1"
                x-small
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-list-item-content class="pa-0">
            <v-btn
              v-if="$store.getters.isLoggedIn"
              class="justify-start text-none drawer-v-btn"
              text
              @click="logout"
            >
              {{ $t('se-deconnecter') }}
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-list-item-content class="pa-0 pb-2">
            <span
              v-if="$store.getters.isLoggedIn"
              class="justify-start muted--text px-4 text-subtitle-2"
            >
              {{ $t('connecte', {adresse: user.email}) }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

import Welcome from '@/components/Welcome'
import Avis from '@/components/Avis'
import PartageEC from '@/components/PartageEC'

import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Drawer',
  components: {
    Welcome,
    Avis,
    PartageEC
  },
  data () {
    return {
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL,
      petiteSourceUrl: process.env.VUE_APP_PETITE_SOURCE_URL,
      piscinelleAccessoiresUrl: process.env.VUE_APP_PISCINELLE_ACCESSOIRES,
      drawer: !!this.$vuetify.breakpoint.mdAndUp,
      pageLoading: true
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    ...mapState({
      commandes: state => state.commandes.list
    })
  },
  watch: {
    $route: 'loadInfos'
  },
  created () {
    this.loadInfos() // Charger les commandes
    setInterval(() => this.loadInfos(), 10800000) // Refresh commandes toutes les 3 heures
    setInterval(() => this.updateVisites(), 60000) // Refresh visite toutes les minutes
  },
  methods: {
    ...mapActions(['getCommandes']),
    logout () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push({ name: 'Login' })
        })
    },
    loadInfos () {
      this.getCommandes(true).then(res => {
        this.pageLoading = false
      })
    },
    updateVisites () {
      if (document.hasFocus()) {
        this.$store.dispatch('updateVisite', this.$route.path).then(() => {

        })
      }
    }
  }
}
</script>
